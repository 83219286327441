





























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class HmSimpleTable extends Vue {
    @Prop(Boolean)
    isFetching!: boolean;

    @Prop(Array)
    list!: unknown[];

    @Prop(Array)
    headers!: string[];
}
